import { graphql, Link } from 'gatsby';
import React, { Fragment, ReactNode } from 'react';
import BaseComponent from '../../../base/base-component';
import StateOfReformMapChart from '../../../components/charts/map/sor/sor-map-chart';
import ContainerBox from '../../../components/container-box/container-box';
import ShareContainer from '../../../components/share-container/share-container';
import SocialMetadata from '../../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../../components/social-metadata/social-metadata-enum';
import SvgIcon from '../../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../../components/svg-icon/svg-icon-enum';
import { Route } from '../../../global/route-enum';
import { IEmbeddedSORMapProperties } from './sor-map-interface';

export default class EmbeddedSORMap extends BaseComponent<IEmbeddedSORMapProperties> {
  protected declareTranslateCollection(): string {
    return 'sorMapTemplate';
  }

  public render(): ReactNode {
    const { data, pageContext } = this.props;
    const { dataType } = pageContext;

    return (
      <Fragment>
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={this.translate(`${dataType}Title`)}
          description={this.translate(`${dataType}Description`)}
          imageResourceFileName={`embedded-sor-${dataType}-map.png`}
        />
        <ContainerBox className="c-section--embed">
          <ShareContainer
            className="c-embed__wrap"
            shareName={`sor-${dataType}-map`}
          >
            <div className="c-embed__box">
              <h2 className="c-score__title">
                {this.translate(`${dataType}Title`)}
              </h2>
              <StateOfReformMapChart data={data} dataType={dataType} />
            </div>
            <div className="c-embed__logo">
              <Link to={Route.HOME}>
                <SvgIcon
                  width="60"
                  viewBox="0 0 99 44"
                  paths={[
                    {
                      command: SvgIconPathCommand.STATE_OF_REFORM,
                      fill: '#0d0c0d',
                    },
                  ]}
                />
              </Link>
            </div>
          </ShareContainer>
        </ContainerBox>
      </Fragment>
    );
  }
}

export const query = graphql`
  query GetStateOfReformEmbeddedData {
    all: allSheetDataSor {
      nodes {
        rcv
        state
        code
        primaries
        irc
        id
        voteAtHome
      }
    }
  }
`;
